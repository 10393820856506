<template>
  <div v-loading="loading" class="teacher">
    <div class="operate">
      <filter-component
        class="filters"
        :options="filterOptions"
        @change="getFilterValue"
      ></filter-component>
      <div class="btn">
        <el-button type="primary" plain @click="add">新增</el-button>
        <el-button type="success" plain @click="importTeacher"
          >批量新增</el-button
        >
        <el-button
          type="success"
          plain
          :loading="exportLoading"
          @click="exportTeacher"
          >导出</el-button
        >
        <el-button type="primary" plain @click="batchEdit">批量修改</el-button>
        <el-button type="primary" plain @click="clearRole"
          >批量清除用户权限</el-button
        >
        <el-button type="primary" plain @click="importTrack"
          >导入跟踪生</el-button
        >
      </div>
    </div>
    <div class="tableList">
      <el-table
        :data="tableData"
        border
        style="width: 100%"
        @selection-change="handleSelectionChange"
      >
        <el-table-column type="selection" width="55" align="center">
        </el-table-column>
        <el-table-column
          v-for="(item, index) in labelList"
          :key="'sort' + index"
          :prop="item.prop"
          :width="item.width ? item.width : 'auto'"
          :label="item.label"
          :align="item.align ? item.align : 'center'"
        >
          <template slot-scope="{ row }">
            <div v-if="item.prop === 'levelId'">
              <span>{{ filterLevelName(row.levelId) }}</span>
            </div>
            <div v-else-if="item.prop === 'schoolTeacherRoleList'">
              <div
                v-for="(it, ind) in row.schoolTeacherRoleList"
                :key="ind + 'role'"
                class="role"
              >
                {{ getTeacherRoleByType(it.type) }}
                <span
                  v-if="
                    it.type != 5 && (it.subjectId || it.year || it.classNums)
                  "
                >
                  ({{ getLevelName(it.level) }}{{ it.year
                  }}{{ getTeacherSubject(it.subjectId)
                  }}{{ it.classNums ? `,${it.classNums}` : "" }})
                </span>
                <span v-if="it.type == 5 && it.level">
                  ({{ getLevelName(it.level) }})
                </span>
                <span v-if="it.type == 6">
                  (楼栋:{{ it.building }},楼层:{{ it.floor }})
                </span>
              </div>
            </div>
            <div v-else-if="item.prop === 'subjectName'">
              {{ getSubjectName(row.subjectId) }}
            </div>
            <div v-else-if="item.prop === 'schoolIdSub'">
              {{ getSchoolName(row.schoolIdSub) }}
            </div>
            <span v-else>{{ row[item.prop] }}</span>
          </template>
        </el-table-column>
        <el-table-column
          :key="'labelList99'"
          label="操作"
          width="150"
          align="center"
        >
          <template slot-scope="{ row }">
            <el-button type="text" @click="addTrack(row)">跟踪生</el-button>
            <el-button type="text" @click="edit(row)">修改</el-button>
            <el-button type="text" @click="del(row)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="pagination">
      <el-pagination
        :current-page="page.current"
        :page-sizes="[10, 20, 50, 100, 200, 500, 1000]"
        :page-size="page.size"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      >
      </el-pagination>
    </div>

    <footer-tab
      v-if="showTab"
      :multiple-selection="multipleSelection"
      @delete="deleteTeacher"
      @hidden="hidden"
    >
    </footer-tab>
    <el-dialog
      :title="title"
      :visible.sync="dialogVisible"
      :width="getDialogWidth()"
      destroy-on-close
      :before-close="handleClose"
    >
      <div v-if="title === '批量清除用户权限'">
        <div class="filter">
          <div class="row">
            <span>学段：</span>
            <el-select
              v-model="clear.level"
              style="width: 112px"
              clearable
              placeholder="全部学段"
            >
              <el-option
                v-for="item in levelOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </div>
          <div class="row">
            <span>年级：</span>

            <el-select v-model="clear.year" clearable placeholder="全部年级">
              <el-option
                v-for="item in yearList"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </div>
        </div>
        <div class="filter">
          <div class="row">
            <span>角色：</span>
            <el-checkbox-group v-model="clear.types">
              <el-checkbox :label="5">校领导</el-checkbox>
              <el-checkbox :label="4">年级组长</el-checkbox>
              <el-checkbox :label="3">备课组长</el-checkbox>
              <el-checkbox :label="2">班主任</el-checkbox>
              <el-checkbox :label="1">任课老师</el-checkbox>
              <el-checkbox :label="6">宿管</el-checkbox>
              <el-checkbox :label="7">排课老师</el-checkbox>
            </el-checkbox-group>
          </div>
        </div>
      </div>
      <div v-if="title === '批量导入教师'" class="batch">
        <div class="text">账号使用说明：</div>
        <div class="text">
          1、唯一标识用户名：使用唯一标识录入用户名列（例：使用手机号作为唯一标识）；
        </div>
        <div class="text">
          2、随机生成用户名：不录入用户名列，系统会随机生成用户名，若存在重名教师请一定将姓名做区；
        </div>
        <div class="text">角色填写注意事项：</div>
        <div class="text">
          1、教师存在多个角色时，需要录入多行数据一个角色一条，其中姓名，用户名，密码，科目，分类信息需要一致；
        </div>
        <div class="text">
          2、教师存在多个角色且使用随机生成用户名时，请将重名教师区分；
        </div>
        <div class="template">
          文件上传：
          <el-button type="text" @click="downloadTemplate"
            >{{ roleId == 6 ? "用户信息模板下载" : "教师信息模版下载"
            }}<i class="el-icon-download"></i
          ></el-button>
        </div>
      </div>
      <div v-if="title === '批量修改'" class="batch mb20">
        <div class="text">温馨提示：</div>
        <div class="text">
          1、批量修改教师将修改除 账号
          以外的所有教师属性信息（包括：姓名、学段、科目、角色权限等）。
        </div>
        <div class="text">
          2、请先使用导出功能导出需要修改的教师，然后在Excel中修改教师信息，最后使用批量修改功能导入Excel修改。
        </div>
      </div>
      <div v-if="title === '导入跟踪生'" class="batch">
        <div class="template">
          当前仅支持Excel文件，
          <el-link type="primary" :href="templateUrl">
            下载Excel模板<i class="el-icon-download"></i>
          </el-link>
        </div>
      </div>
      <el-upload
        v-if="
          title === '批量导入教师' ||
          title === '批量修改' ||
          title === '导入跟踪生'
        "
        ref="upload"
        class="upload-demo"
        name="file"
        drag
        :data="uploadData"
        :action="uploadAvatarURL"
        :headers="uploadAvatarHeaders"
        :on-remove="handleRemove"
        :file-list="fileList"
        :on-success="handleOnSuccess"
        :on-error="handleOnError"
        :auto-upload="false"
        :before-upload="beforeUpload"
        multiple
      >
        <i class="el-icon-upload"></i>
        <div class="el-upload__text">请将文件拖放到此处或点击上传</div>
      </el-upload>
      <el-form
        v-show="
          title === '新增教师' ||
          title === '新增用户' ||
          title === '编辑用户' ||
          title === '编辑教师'
        "
        ref="form"
        label-position="right"
        label-width="60px"
        :model="form"
        :rules="rules"
      >
        <el-row>
          <el-col :span="12">
            <el-form-item label="姓名" prop="name">
              <el-input
                v-model="form.name"
                style="width: 260px"
                placeholder="请输入姓名"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="账号" prop="username">
              <el-input
                v-model="form.username"
                style="width: 260px"
                placeholder="请输入账号"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="密码" prop="password">
              <el-input
                v-model="form.password"
                style="width: 260px"
                placeholder="请输入密码"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="学段">
              <el-select
                v-model="levelIds"
                multiple
                placeholder="请选择"
                clearable
                style="width: 260px"
                @change="levelIdsChange"
              >
                <el-option
                  v-for="item in levelOptions"
                  :key="item.value"
                  :label="item.label"
                  :value="item"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row v-if="roleId == 6">
          <el-col :span="24">
            <el-form-item label="学校" prop="schoolIdSub">
              <el-select
                v-model="form.schoolIdSub"
                clearable
                placeholder="请选择学校"
              >
                <el-option
                  v-for="item in schoolList"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="手机号" prop="phone">
              <el-input
                v-model="form.phone"
                placeholder="请输入手机号"
                style="width: 260px"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24">
            <el-form-item label="科目" prop="subjectIds">
              <div class="subjectOptions">
                <div class="row">
                  <el-button type="text" @click="allCheckChange"
                    >全选</el-button
                  >
                  <el-button
                    type="text"
                    style="margin-left: 10px"
                    @click="reverseChange"
                    >反选</el-button
                  >
                  <el-button type="text" @click="cancleAllChange"
                    >取消全部</el-button
                  >
                </div>
                <div class="checkbox">
                  <el-checkbox-group
                    v-model="form.subjectIds"
                    @change="handleCheckedChange"
                  >
                    <el-checkbox
                      v-for="item in subjectOptions"
                      :key="item.value + 'subjectOptions'"
                      :label="item.value"
                      >{{ item.label }}
                    </el-checkbox>
                  </el-checkbox-group>
                </div>
              </div>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row v-if="roleId != 6">
          <el-col :span="24">
            <el-form-item
              v-for="(item, index) in form.schoolTeacherRoleList"
              :key="index"
              :prop="'schoolTeacherRoleList.' + index + '.level'"
              :rules="{
                require: true,
                validator: (rule, value, callback) =>
                  checkValueType(rule, value, callback, index),
                trigger: 'change',
              }"
              :label="index === 0 ? '角色' : ''"
            >
              <div class="row">
                <el-radio-group
                  v-model="form.schoolTeacherRoleList[index].type"
                  @change="(value) => radioChange(value, index)"
                >
                  <el-radio
                    v-for="(i, n) in teacherRole"
                    :key="'role' + n"
                    :label="i.value"
                    >{{ i.label }}</el-radio
                  >
                </el-radio-group>
                <i class="el-icon-delete" @click="delItem(item, index)"></i>
              </div>
              <div class="roleMsg">
                <el-input
                  v-if="item.type === 6"
                  v-model="item.building"
                  style="width: 180px; margin-right: 15px"
                  placeholder="楼栋"
                ></el-input>
                <el-input
                  v-if="item.type === 6"
                  v-model="item.floor"
                  style="width: 180px; margin-right: 15px"
                  placeholder="楼层"
                ></el-input>
                <el-select
                  v-if="![6, 7].includes(item.type)"
                  v-model="item.level"
                  style="width: 180px; margin-right: 15px"
                  placeholder="学段"
                  @change="levelChange(item, index)"
                >
                  <el-option
                    v-for="it in levelIds"
                    :key="it.value + '7777'"
                    :label="it.label"
                    :value="it.value"
                  >
                  </el-option>
                </el-select>
                <el-date-picker
                  v-if="![5, 6, 7].includes(item.type)"
                  v-model="item.year"
                  style="width: 180px; margin-right: 15px"
                  type="year"
                  value-format="yyyy"
                  placeholder="入学年份"
                >
                </el-date-picker>
                <el-select
                  v-show="item.type == 3 || item.type == 1"
                  :key="tokenKey"
                  v-model="item.subjectId"
                  style="width: 180px; margin-right: 15px"
                  placeholder="科目"
                  @change="specChange"
                >
                  <el-option
                    v-for="(it, ind) in item.subjectList"
                    :key="ind + 'molly'"
                    :label="it.label"
                    :value="it.value"
                  >
                  </el-option>
                </el-select>
                <el-input
                  v-if="item.type == 2 || item.type == 1"
                  v-model="item.classNums"
                  style="width: 180px; margin-right: 15px"
                  placeholder="班级 用,隔开"
                ></el-input>
              </div>
            </el-form-item>
          </el-col>
        </el-row>
        <el-button
          v-if="roleId != 6"
          type="primary"
          plain
          style="margin-left: 100px"
          @click="pushTecherRole"
          >继续添加</el-button
        >
      </el-form>
      <el-dialog
        width="30%"
        title="温馨提示"
        :visible.sync="innerVisible"
        append-to-body
      >
        <div class="clearTip">
          此操作将清除所有选中教师权限，确认重评请输入的登录密码，否则点击取消;
        </div>
        <div class="password">
          请输入登录密码：
          <a-input-password
            v-model="password"
            show-password
            style="width: 150px"
            placeholder="请输入登录密码"
          />
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button @click="innerVisible = false">取 消</el-button>
          <el-button
            v-loading="submitLoading"
            type="danger"
            @click="clearAllRole"
            >清除</el-button
          >
        </span>
      </el-dialog>
      <span slot="footer" class="dialog-footer">
        <el-button @click="handleClose">取 消</el-button>
        <el-button
          v-if="title === '批量清除用户权限'"
          type="primary"
          @click="innerVisible = true"
          >确认
        </el-button>
        <el-button
          v-if="
            title === '新增教师' ||
            title === '新增用户' ||
            title === '编辑用户' ||
            title === '编辑教师'
          "
          v-loading="submitLoading"
          type="primary"
          @click="submit"
        >
          确认</el-button
        >
        <el-button
          v-if="title === '清除'"
          v-loading="submitLoading"
          type="danger"
          >清除</el-button
        >
        <el-button
          v-if="
            title === '批量导入教师' ||
            title === '导入跟踪生' ||
            title === '批量修改'
          "
          v-loading="importLoading"
          type="primary"
          @click="submitImport"
          >导入
        </el-button>
      </span>
    </el-dialog>
    <addTrack v-if="showTrack" :teacher-id="teacherId" @close="handleClose" />
  </div>
</template>
<script>
import filterComponent from "@/components/filter";
import {
  schoolteacher,
  editSchoolteacher,
  delSchoolteacher,
  addSchoolteacher,
  removeTeacher,
  exportteacher,
  cleanTeacher,
  exportteacherUser,
} from "@/core/api/newOther";
import addTrack from "./component/addTrack.vue";
import { getSchoolIds } from "@/core/api/exam/exam";
import {
  getSubjectList,
  getSubjectListByLevelArr,
  encryption,
  getLevelSubject,
  getYear,
} from "@/core/util/util";
import footerTab from "./component/footerTab.vue";
import { teacherRole, levelOptions } from "@/core/util/constdata";
import { getStore } from "@/core/util/store";
import validate from "@/core/util/formValidate";
export default {
  components: {
    filterComponent,
    footerTab,
    addTrack,
  },
  data() {
    const { primarySubject, juniorSubject, highSubject } = getLevelSubject();
    // const nowYear = new Date().getFullYear();
    // const arr = new Array(nowYear - 2014).fill("");
    // let yearList = arr.map((item, index) => {
    //   return { label: index + 2015, value: index + 2015 };
    // });
    const labelList = [
      {
        label: "姓名",
        prop: "name",
        type: "normal",
        width: 100,
        sort: 1,
      },
      {
        label: "账号",
        prop: "username",
        type: "normal",
        sort: 2,
      },

      {
        label: "密码",
        prop: "password",
        type: "normal",
        width: 100,
        sort: 3,
      },
      {
        label: "学段",
        prop: "levelId",
        type: "normal",
        width: 100,
        sort: 4,
      },
      // {
      //   label: "分类",
      //   prop: "type",
      //   type: "normal",
      //   width: 120,
      //   sort: 4,
      // },
      {
        label: "手机号",
        prop: "phone",
        type: "normal",
        align: "center",
        sort: 5,
      },
      {
        label: "科目",
        prop: "subjectName",
        type: "normal",
        align: "center",

        sort: 6,
      },
    ];
    const { roleId } = JSON.parse(
      localStorage.getItem("jzjx-userInfo")
    ).content;
    if (roleId != 6) {
      labelList.push({
        label: "角色权限",
        prop: "schoolTeacherRoleList",
        type: "normal",
        align: "center",
        sort: 6,
      });
    } else {
      labelList.push({
        label: "学校",
        prop: "schoolIdSub",
        type: "normal",
        align: "center",
        sort: 6,
      });
    }
    // const userMsg = JSON.parse(localStorage.getItem("jzjx-user-message"));
    const password = new RegExp("[\\u4E00-\\u9FFF]+", "g"); // 不是中文
    const validateUsername = (rule, value, callback) => {
      if (value) {
        if (!/^[\u4e00-\u9fa5a-zA-Z0-9_]+$/.test(value)) {
          callback(new Error("账号格式为汉字、字母、数字、下划线组合"));
        } else {
          callback();
        }
      } else {
        callback();
      }
    };
    const validatePassword = (rule, value, callback) => {
      if (value) {
        if (password.test(value)) {
          callback(new Error("密码格式不正确"));
        } else {
          callback();
        }
      } else {
        callback();
      }
    };
    const validateSubjectIds = (rule, value, callback) => {
      // if (value.length) {
      callback();
      // } else {
      //  callback(Error("请选择科目"));
      // }
    };
    const checkValueType = (rule, value, callback, index) => {
      let type = this.form.schoolTeacherRoleList[index].type;
      let item = this.form.schoolTeacherRoleList[index];
      if (type) {
        if (type === 1) {
          if (item.level && item.year && item.subjectId && item.classNums) {
            callback();
          } else {
            callback(new Error("请完成角色信息填写"));
          }
        } else if (type === 2) {
          if (item.level && item.year && item.classNums) {
            callback();
          } else {
            callback(new Error("请完成角色信息填写"));
          }
        } else if (type === 3) {
          if (item.level && item.year && item.subjectId) {
            this.$delete(item, "classNums");
            callback();
          } else {
            callback(new Error("请完成角色信息填写"));
          }
        } else if (type === 4) {
          if (item.level && item.year) {
            callback();
          } else {
            callback(new Error("请完成角色信息填写"));
          }
        } else if (type === 5) {
          if (item.level) {
            callback();
          } else {
            callback(new Error("请选择学段"));
          }
        } else if (type === 6) {
          if (item.building && item.floor) {
            callback();
          } else {
            callback(new Error("请完成角色信息填写"));
          }
        } else if (type === 7) {
          callback();
        }
      } else {
        callback(new Error("请选择角色"));
      }
    };
    var phoneReg =
      /^(((13[0-9]{1})|(14[0-9]{1})|(15[0-9]{1})|(16[0-9]{1})|(17[0-9]{1})|(19[0-9]{1})|(18[0-9]{1}))+\d{8})$/;
    const validatePhone = (rules, value, callback) => {
      if (!value) {
        callback();
      } else {
        if (!phoneReg.test(value)) {
          callback(new Error("手机号格式不正确"));
        } else {
          callback();
        }
      }
    };
    return {
      levelIds: [],
      oldLevelIds: [],
      primarySubject: primarySubject,
      juniorSubject: juniorSubject,
      highSubject: highSubject,
      password: null,
      innerVisible: false,
      filterOptions: [
        {
          label: "学段",
          field: "roleLevel",
          type: "select",
          placeholder: "全部学段",
          options: levelOptions(),
          labelKey: "label",
          valueKey: "value",
        },
        {
          label: "年份",
          type: "select",
          field: "roleYear",
          placeholder: "全部年份",
          options: [...getYear()],
          labelKey: "label",
          valueKey: "value",
        },
        {
          label: "角色",
          type: "select",
          field: "roleType",
          placeholder: "全部角色",
          options: [...teacherRole],
          labelKey: "label",
          valueKey: "value",
        },

        {
          label: "科目",
          field: "roleSubjectId",
          type: "select",
          placeholder: "全部科目",
          options: [],
          labelKey: "label",
          valueKey: "value",
        },
        {
          label: "",
          type: "input",
          field: "searchKey",
          placeholder: "搜索老师名称",
        },
      ],

      subjectOptions: [],
      teacherRole: teacherRole,
      levelOptions: levelOptions(),
      checkValueType: checkValueType,
      multipleSelection: [],
      rules: {
        name: validate.normal("请输入姓名"),
        username: [{ validator: validateUsername, trigger: "blur" }],
        password: [{ validator: validatePassword, trigger: "blur" }],
        level: validate.selectNormal("请选择学段"),
        phone: [{ validator: validatePhone, trigger: "blur" }],
        subjectIds: [
          { required: false, validator: validateSubjectIds, trigger: "change" },
        ],
        schoolIdSub: [
          { required: true, message: "请选择学校", trigger: "change" },
        ],
      },

      fileList: [],
      clear: {
        types: [],
      },
      checkedSubject: [],
      subjects: [],
      isIndeterminate: false,
      allCheck: [],
      reverse: false,
      cancleAll: false,
      search: {
        level: null,
      },
      page: {
        current: 1,
        size: 50,
      },
      form: {
        phone: "",
        subjectIds: [],
        schoolTeacherRoleList: [
          {
            type: 1,
            subjectId: "",
          },
        ],
      },
      title: "",
      loading: false,
      tableData: [],
      total: 0,
      labelList: labelList,
      older: [],
      showTab: false,
      submitLoading: false,
      dialogVisible: false,
      importLoading: false,
      exportLoading: false,
      schoolList: [],
      time: new Date().getTime(),
      uploadAvatarURL: null,
      uploadAvatarHeaders: {
        Authorization: "Bearer " + getStore({ name: "access_token" }),
        "SCHOOL-ID": getStore({ name: "school_id" }),
      },
      roleId: "",
      choosedSubjects: [],
      templateUrl: "",
      yearList: [...getYear()],
      schoolId: "",
      teacherId: "",
      showTrack: false,
      uploadData: {},
      tokenKey: Math.random(),
    };
  },
  watch: {
    "form.subjectIds": {
      deep: true,
      handler(val) {
        if (!val.length) {
          this.form.schoolTeacherRoleList.forEach((item) =>
            this.$set(item, "subjectList", [])
          );
        }
      },
    },
  },
  created() {
    this.subjectOptions = getSubjectList();
    this.filterOptions[3].options = this.subjectOptions;
    const { roleId, schoolId } = JSON.parse(
      localStorage.getItem("jzjx-userInfo")
    ).content;
    this.roleId = roleId;
    this.schoolId = schoolId;
  },
  mounted() {
    this.getData();
  },
  methods: {
    downloadTemplate() {
      var suffix = this.templateUrl.substring(
        this.templateUrl.lastIndexOf(".")
      );
      const { href } = this.$router.resolve({
        path: "/downFile",
        query: {
          url: this.templateUrl,
          name:
            this.roleId == 6
              ? "用户信息模板下载" + suffix
              : "教师信息模版下载" + suffix,
          type: 1,
        },
      });
      let timer = setTimeout(() => {
        window.open(href, "_blank");
        clearTimeout(timer);
      }, 0);
    },
    getLevelName(level) {
      if (level) {
        const data = [
          { label: "小学", value: 1 },
          { label: "初中", value: 2 },
          { label: "高中", value: 3 },
          { label: "大学", value: 4 },
        ].find((item) => item.value == level);
        return data.label.slice(0, 1);
      }
    },
    addTrack(row) {
      this.teacherId = row.id;
      this.showTrack = true;
    },
    getDialogWidth() {
      const miniWidth = ["导入跟踪生"];
      if (miniWidth.includes(this.title)) {
        return "30%";
      } else {
        return "50%";
      }
    },

    beforeUpload() {},
    batchEdit() {
      this.title = "批量修改";
      this.dialogVisible = true;
      this.uploadAvatarURL = "/cms/schoolteacher/import";
      this.uploadData = {
        type: 2,
      };
    },
    importTrack() {
      this.title = "导入跟踪生";
      this.dialogVisible = true;
      this.uploadAvatarURL = "/cms/trackstudent/importtrackstudent";
      this.templateUrl =
        "https://static.wtjy.com/cms/template/%E8%B7%9F%E8%B8%AA%E7%94%9F%E5%AF%BC%E5%85%A5%E6%A8%A1%E6%9D%BF.xls";
    },
    specChange() {
      this.$forceUpdate();
    },
    radioChange(value, index) {
      this.tokenKey = Math.random();
      if (value == 5) {
        this.$delete(this.form.schoolTeacherRoleList[index], "level ");
        this.$delete(this.form.schoolTeacherRoleList[index], "year ");
        this.$delete(this.form.schoolTeacherRoleList[index], "subjectId");
        this.$delete(this.form.schoolTeacherRoleList[index], "classNums ");
      } else if (value == 4) {
        this.$delete(this.form.schoolTeacherRoleList[index], "subjectId");
        this.$delete(this.form.schoolTeacherRoleList[index], "classNums ");
      } else if (value == 3) {
        this.$set(this.form.schoolTeacherRoleList[index], "subjectId", null);
        this.$delete(this.form.schoolTeacherRoleList[index], "classNums ");
      } else if (value == 2) {
        this.$delete(this.form.schoolTeacherRoleList[index], "subjectId");
      } else if (value == 1) {
        this.$set(this.form.schoolTeacherRoleList[index], "subjectId", null);
      } else if (value === 6) {
        this.$delete(this.form.schoolTeacherRoleList[index], "level ");
        this.$delete(this.form.schoolTeacherRoleList[index], "year ");
        this.$delete(this.form.schoolTeacherRoleList[index], "subjectId");
        this.$delete(this.form.schoolTeacherRoleList[index], "classNums ");
        this.$set(this.form.schoolTeacherRoleList[index], "floor", null);
        this.$set(this.form.schoolTeacherRoleList[index], "building", null);
      } else if (value === 7) {
        this.$delete(this.form.schoolTeacherRoleList[index], "level ");
        this.$delete(this.form.schoolTeacherRoleList[index], "year ");
        this.$delete(this.form.schoolTeacherRoleList[index], "subjectId");
        this.$delete(this.form.schoolTeacherRoleList[index], "classNums ");
        this.$delete(this.form.schoolTeacherRoleList[index], "floor ");
        this.$delete(this.form.schoolTeacherRoleList[index], "building ");
      }
    },
    levelIdsChange() {
      // this.form.schoolTeacherRoleList.forEach((item) => {
      //   this.$set(item, "level", null);
      // });
      this.clearTeacherRole();

      this.form.subjectIds = [];
      this.subjectOptions = getSubjectListByLevelArr(
        this.levelIds.map((item) => item.value)
      );
      this.choosedSubjects = [];
    },
    getSubjectName(subjectId) {
      if (subjectId) {
        const arr = subjectId.split(",");

        const labelArr = this.subjectOptions.filter((item) => {
          if (arr.includes(item.value.toString())) {
            return item;
          }
        });
        const subjectName = labelArr.map((item) => item.label).toString();
        return subjectName;
      }
    },
    getSchoolName(id) {
      const data = this.schoolList.find((item) => item.id === id);
      if (data) {
        return data.name;
      }
    },
    levelChange(item, index, type) {
      if (item.level == 1) {
        const subjectIds = this.primarySubject.map((item) => item.value);
        this.form.schoolTeacherRoleList[index].subjectList =
          this.choosedSubjects.filter((item) =>
            subjectIds.includes(item.value)
          );
      } else if (item.level == 2) {
        const subjectIds = this.juniorSubject.map((item) => item.value);
        this.form.schoolTeacherRoleList[index].subjectList =
          this.choosedSubjects.filter((item) =>
            subjectIds.includes(item.value)
          );
      } else if (item.level == 3) {
        const subjectIds = this.highSubject.map((item) => item.value);
        this.form.schoolTeacherRoleList[index].subjectList =
          this.choosedSubjects.filter((item) =>
            subjectIds.includes(item.value)
          );
      } else if (item.level == 4) {
        const subjectIds = getSubjectList().map((item) => item.value);
        this.form.schoolTeacherRoleList[index].subjectList =
          this.choosedSubjects.filter((item) =>
            subjectIds.includes(item.value)
          );
      }
      if (type != "edit") {
        this.form.schoolTeacherRoleList[index].subjectId = "";
      }
      this.$forceUpdate();
    },
    async exportTeacher() {
      this.exportLoading = true;
      if (this.roleId == 6) {
        try {
          const res = await exportteacherUser({ ...this.search });
          const { url, name } = res.data.data;
          const { href } = this.$router.resolve({
            path: "/downFile",
            query: {
              url: url,
              name: name,
              type: 1,
            },
          });
          let timer = setTimeout(() => {
            window.open(href, "_blank");
            clearTimeout(timer);
          }, 0);
          this.exportLoading = false;
        } catch {
          this.exportLoading = false;
        }
      } else {
        try {
          const res = await exportteacher({ ...this.search });
          const { url, name } = res.data.data;
          const { href } = this.$router.resolve({
            path: "/downFile",
            query: {
              url: url,
              name: name,
              type: 1,
            },
          });
          let timer = setTimeout(() => {
            window.open(href, "_blank");
            clearTimeout(timer);
          }, 0);
          this.exportLoading = false;
        } catch {
          this.exportLoading = false;
        }
      }
    },
    clearRole() {
      this.title = "批量清除用户权限";
      this.dialogVisible = true;
    },
    clean() {
      const h = this.$createElement;
      this.$msgbox({
        title: "提示",
        message: h("p", null, [
          h("span", null, "该操作将删除"),
          h("span", { style: "color:#FF0000" }, "教务用户以外"),
          h("span", null, "的"),
          h("span", { style: "color:#FF0000" }, "所有教师"),
          h("span", null, "，确认清空吗？"),
        ]),
        showCancelButton: true,
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.deleteTeacher();
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    del(row) {
      this.$confirm("此操作将永久删除该教师信息, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          delSchoolteacher({ id: row.id }).then(() => {
            this.$message({
              type: "success",
              message: "删除成功!",
            });
            this.getData();
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    pushTecherRole() {
      this.form.schoolTeacherRoleList.push({ type: 1, subjectId: "" });
    },
    delItem(i, n) {
      this.form.schoolTeacherRoleList.splice(n, 1);
    },
    clearTeacherRole() {
      const levelIds = this.levelIds.map((item) => item.value);
      this.form.schoolTeacherRoleList.forEach((item) => {
        if (!levelIds.includes(item.level)) {
          this.$set(item, "level", null);
          this.$set(item, "subjectId", null);
        }
        if (item.level) {
          this.form.schoolTeacherRoleList.forEach((item, index) =>
            this.levelChange(item, index)
          );
        }
      });
    },
    handleCheckedChange() {
      this.choosedSubjects = this.subjectOptions.filter((item) =>
        this.form.subjectIds.includes(item.value)
      );
      this.tokenKey = Math.random();
      this.clearTeacherRole();
      this.$forceUpdate();
    },
    allCheckChange() {
      this.form.subjectIds = this.subjectOptions.map((item) => item.value);
      this.choosedSubjects = JSON.parse(JSON.stringify(this.subjectOptions));
      this.$forceUpdate();
      this.clearTeacherRole();
    },
    reverseChange() {
      const subjectIds = this.subjectOptions.map((item) => item.value);
      this.form.subjectIds = subjectIds.filter(
        (item) => !this.form.subjectIds.includes(item)
      );
      this.choosedSubjects = this.subjectOptions.filter((item) => {
        return this.form.subjectIds.includes(item.value);
      });

      this.allCheck = this.form.subjectIds.length === subjectIds.length;

      this.clearTeacherRole();
      this.$forceUpdate();
    },
    cancleAllChange() {
      this.allCheck = false;
      this.form.subjectIds = [];
      this.$forceUpdate();
      this.clearTeacherRole();
    },
    async importTeacher() {
      this.title = "批量导入教师";
      if (this.roleId == 6) {
        const res = await getSchoolIds({ type: 1, id: this.schoolId });
        this.schoolList = res.data.data;
        this.uploadAvatarURL = "/cms/schoolteacher/import/user";
        this.templateUrl = `https://static.wtjy.com/cms/template/%E6%95%99%E5%B8%88%E5%AF%BC%E5%85%A5%E6%A8%A1%E6%9D%BF.xls`;
      } else {
        this.uploadData = {
          type: 1,
        };
        this.templateUrl = `https://static.wtjy.com/cms/template/%E6%95%99%E5%B8%88%E5%AF%BC%E5%85%A5%E6%A8%A1%E6%9D%BF.xls`;
        this.uploadAvatarURL = "/cms/schoolteacher/import";
      }
      this.dialogVisible = true;
    },
    filterLevelName(levelId) {
      const arr = levelId && levelId.split(",").filter((item) => Number(item));
      if (arr.length) {
        let total = arr.reduce((total, item) => {
          const data = [
            { label: "小学", value: 1 },
            { label: "初中", value: 2 },
            { label: "高中", value: 3 },
            { label: "大学", value: 4 },
          ].find((it) => item == it.value);
          if (total.length) {
            return total + "," + data.label;
          } else {
            return data.label;
          }
        }, "");

        return total;
      } else {
        return "-";
      }
    },
    add() {
      const { roleId } = JSON.parse(
        localStorage.getItem("jzjx-userInfo")
      ).content;
      this.title = roleId == 6 ? "新增用户" : "新增教师";
      this.dialogVisible = true;
      this.form = {
        subjectIds: [],
        schoolTeacherRoleList: [
          {
            type: 1,
            subjectId: "",
          },
        ],
      };
    },
    edit(row) {
      const { roleId } = JSON.parse(
        localStorage.getItem("jzjx-userInfo")
      ).content;
      this.title = roleId == 6 ? "编辑用户" : "编辑教师";
      this.form = { ...row };
      let subjectArr =
        this.form.subjectId &&
        this.form.subjectId.split(",").map((item) => Number(item));
      this.$set(this.form, "subjectIds", subjectArr || []);
      this.levelIds = this.levelOptions.filter(
        (item) => row.levelId.indexOf(item.value.toString()) > -1
      );
      this.form.schoolTeacherRoleList = JSON.parse(
        JSON.stringify(row.schoolTeacherRoleList)
      );
      this.choosedSubjects = this.subjectOptions.filter((item) =>
        this.form.subjectIds.includes(item.value)
      );
      this.form.schoolTeacherRoleList.forEach((item, index) => {
        this.levelChange(item, index, "edit");
        item.year = item.year ? item.year.toString() : "";
        item.subjectId = item.subjectId ? item.subjectId : "";
        item.level = item.level ? item.level.toString() : null;
      });

      this.dialogVisible = true;
    },
    clearAllRole() {
      const userMsg = JSON.parse(localStorage.getItem("jzjx-user-message"));
      this.clear.type = this.clear.types.toString();
      this.submitLoading = true;
      const password = this.password;
      let randomStr = "blockPuzzle";
      let code = "xxx";
      const user = encryption({
        data: { password, randomStr, code },
        key: "pigxpigxpigxpigx",
        param: ["password"],
      });
      cleanTeacher({
        year: this.clear.year,
        level: this.clear.level,
        type: this.clear.type,
        schoolId: userMsg.content.schoolId,
        password: user.password,
      })
        .then(() => {
          this.$message({
            type: "success",
            message: "清除成功!",
          });
          this.getData();
          this.clear = {
            types: [],
          };
          this.submitLoading = this.dialogVisible = this.innerVisible = false;
        })
        .catch(() => {
          this.submitLoading = this.dialogVisible = this.innerVisible = false;
        });
    },
    formatterParams() {
      let schoolTeacherRoleList = this.form.schoolTeacherRoleList;
      schoolTeacherRoleList.map((item) => {
        if (item.type === 1) {
          return { ...item };
        } else if (item.type === 2) {
          return {
            classNums: item.classNums,
            level: item.level,
            type: item.type,
            year: item.year,
          };
        } else if (item.type === 3) {
          return {
            level: item.level,
            subjectId: item.subjectId,
            type: item.type,
            year: item.year,
          };
        } else if (item.type === 4) {
          return {
            level: item.level,
            type: item.type,
            year: item.year,
          };
        } else if (item.type === 5) {
          return {
            level: item.level,
            type: item.type,
          };
        } else if (item.type === 6) {
          return {
            type: item.type,
            building: item.building,
            floor: item.floor,
          };
        } else if (item.type === 7) {
          return {
            type: item.type,
          };
        }
      });
      return schoolTeacherRoleList;
    },
    submit() {
      this.form.levelId = this.levelIds.map((item) => item.value).toString();
      this.form.schoolTeacherRoleList.forEach((item) => {
        this.$delete(item, "subjectList");
      });
      this.$refs.form.validate((valid) => {
        if (valid) {
          if (this.title === "新增教师" || this.title === "新增用户") {
            this.submitLoading = true;
            this.form.schoolTeacherRoleList = this.formatterParams(); // 格式化角色数组传的参数
            this.$set(this.form, "subjectId", this.form.subjectIds.toString());
            addSchoolteacher({ ...this.form })
              .then(() => {
                this.dialogVisible = this.submitLoading = false;
                this.getData();
                this.$message.success("提交成功");
                this.levelIds = [];
                this.form = {
                  subjectIds: [],
                  schoolTeacherRoleList: [
                    {
                      type: 1,
                      subjectId: "",
                    },
                  ],
                };
              })
              .catch(() => {
                this.dialogVisible = this.submitLoading = false;
              });
          } else if (this.title === "编辑教师" || this.title === "编辑用户") {
            this.submitLoading = true;
            this.form.schoolTeacherRoleList = this.formatterParams(); // 格式化角色数组传的参数
            editSchoolteacher({
              ...this.form,
              subjectId: this.form.subjectIds.toString(),
            })
              .then(() => {
                this.dialogVisible = this.submitLoading = false;
                this.getData();
                this.$message.success("提交成功");
                this.levelIds = [];
                this.form = {
                  subjectIds: [],
                  schoolTeacherRoleList: [
                    {
                      type: 1,
                      subjectId: "",
                    },
                  ],
                };
              })
              .catch(() => {
                this.dialogVisible = this.submitLoading = false;
              });
          }
        }
      });
    },
    deleteTeacher() {
      removeTeacher({
        teacherIds: this.multipleSelection.map((item) => item.id).toString(),
      }).then(() => {
        this.$message.success("删除成功");
        this.getData();
      });
      this.showTab = false;
    },
    hidden() {
      this.showTab = false;
      this.multipleSelection = [];
    },
    handleRemove() {},
    handleOnError() {
      this.importLoading = false;
    },
    handleOnSuccess(response) {
      if (response.code === 1) {
        if (response.msg.indexOf("http") != -1) {
          window.open(response.msg, "_blank");
          this.$message.error("导入失败");
          this.importLoading = false;
        } else {
          this.$message.error(response.msg);
          this.importLoading = false;
        }
        this.getData();
      } else {
        this.$message.success("导入成功");
        this.importLoading = false;
        this.getData();
      }
      this.dialogVisible = false;
      this.fileList = [];
      this.submitLoading = false;
    },
    submitImport() {
      if (this.$refs.upload.uploadFiles.length) {
        this.importLoading = true;
        this.$refs.upload.submit();
      } else {
        this.$message({
          type: "warning",
          message: "请选择上传的文件",
          showClose: true,
        });
      }
    },
    getTeacherRoleByType(val) {
      const data = teacherRole.find((item) => item.value === val);
      if (data) {
        return data.label;
      } else {
        return "";
      }
    },
    handleClose() {
      this.dialogVisible = false;
      this.fileList = [];
      this.showTrack = false;
    },
    getTeacherSubject(val) {
      const data = this.subjectOptions.find((item) => item.value === val);
      if (data) {
        return `,${data.label}`;
      } else {
        return "";
      }
    },
    getFilterValue(val) {
      this.page.current = 1;
      this.search = { ...val };
      this.filterOptions[3].options = getSubjectList(this.search.roleLevel);
      this.getData();
    },
    async getData() {
      this.loading = true;
      const res = await schoolteacher({ ...this.search, ...this.page });
      this.total = res.data.data.total;
      this.tableData = res.data.data.records;
      this.loading = false;
    },
    handleSizeChange(val) {
      this.page.size = val;
      this.getData();
    },
    handleCurrentChange(val) {
      this.page.current = val;
      this.getData();
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
      if (this.multipleSelection.length > 0) {
        this.showTab = true;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.operate {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  .filters {
    margin-bottom: 20px;
    @media screen and (max-width: 1325px) {
      margin-bottom: 0;
    }
  }
  .btn {
    margin-bottom: 20px;
  }
}

.tableList {
  margin-bottom: 20px;
}

.pagination {
  display: flex;
  justify-content: flex-end;
}

.batch {
  line-height: 24px;
  font-size: 14px;
  color: #101011;

  .template {
    margin-top: 24px;
    margin-bottom: 30px;
  }
}

::v-deep .el-upload-dragger {
  width: 100% !important;
}

::v-deep .el-upload {
  width: 100% !important;
}

.row {
  i {
    font-size: 18px;
    margin-left: 24px;
    color: #bfbfbf;
    cursor: pointer;

    &:hover {
      color: red;
    }
  }
}

::v-deep .el-radio {
  margin-bottom: 0;
}

.roleMsg {
  display: flex;
  align-items: center;
}

.filter {
  display: flex;
  align-items: center;
  margin-bottom: 24px;
  justify-content: flex-start;

  .row {
    display: flex;
    align-items: center;
    margin-right: 24px;
  }
}

::v-deep .app .el-date-editor i {
  display: flex;
  align-items: center;
}
::v-deep .el-checkbox {
  margin-bottom: 0;
}
.clearTip {
  margin-bottom: 10px;
}
</style>
