<template>
  <el-dialog
    title="跟踪生"
    width="50%"
    :before-close="handleClose"
    :visible.sync="trackVisible"
  >
    <div class="filter">
      <div class="row">
        学段：<el-select
          v-model="trackForm.level"
          style="width: 100px"
          clearable
          placeholder="请选择"
          @change="outLevelChange"
        >
          <el-option
            v-for="item in levelOptions"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </div>
      <div class="row">
        入学年份：<el-select
          v-model="trackForm.year"
          clearable
          style="width: 100px"
          placeholder="请选择"
          @change="outLevelChange"
        >
          <el-option
            v-for="item in yearList"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </div>
      <div class="row">
        班级：<el-select
          v-model="trackForm.classNum"
          clearable
          style="width: 100px"
          placeholder="请选择"
          @change="getTrackPage"
        >
          <el-option
            v-for="item in outClassList"
            :key="item.id"
            :label="item.name"
            :value="item.classNum"
          >
          </el-option>
        </el-select>
      </div>
      <div class="row">
        搜索：<el-input
          v-model="trackForm.input"
          clearable
          placeholder="搜索"
          style="width: 100px"
          suffix-icon="el-icon-search"
          @keyup.enter="getTrackPage"
          @input="getTrackPage"
        ></el-input>
        <el-button type="primary" style="margin-left: 12px" @click="addtrack"
          >添加</el-button
        >
      </div>
    </div>
    <div class="tableList">
      <el-table
        v-loading="loading"
        :data="tableData"
        border
        style="width: 100%"
      >
        <el-table-column prop="name" label="姓名" align="center">
        </el-table-column>
        <el-table-column prop="gradeName" label="年级" align="center">
        </el-table-column>
        <el-table-column prop="classNum" label="班级" align="center">
        </el-table-column>
        <el-table-column prop="studentNo" label="学籍号" align="center">
        </el-table-column>
        <el-table-column prop="date" label="操作" align="center">
          <template slot-scope="{ row }">
            <el-button type="text" class="ft-red" @click="del(row)"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="pagination">
      <el-pagination
        :current-page.sync="trackForm.current"
        :page-sizes="[10, 20, 50, 100, 200, 500, 1000]"
        :page-size="trackForm.size"
        layout="sizes, prev, pager, next"
        :total="total"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      >
      </el-pagination>
    </div>
    <el-dialog
      width="50%"
      title="添加跟踪生"
      :visible.sync="innerTrackVisible"
      append-to-body
    >
      <div class="filter">
        <div class="row">
          学段：<el-select
            v-model="innerForm.level"
            style="width: 100px"
            clearable
            placeholder="请选择"
            @change="innerLevelChange"
          >
            <el-option
              v-for="item in levelOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </div>
        <div class="row">
          入学年份：<el-select
            v-model="innerForm.year"
            clearable
            style="width: 100px"
            placeholder="请选择"
            @change="innerLevelChange"
          >
            <el-option
              v-for="item in yearList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </div>
        <div class="row">
          班级：<el-select
            v-model="innerForm.classNum"
            clearable
            style="width: 100px"
            placeholder="请选择"
            @change="innerChange"
          >
            <el-option
              v-for="item in classList"
              :key="item.id"
              :label="item.name"
              :value="item.classNum"
            >
            </el-option>
          </el-select>
        </div>
        <div class="row">
          搜索：<el-input
            v-model="innerForm.input"
            clearable
            placeholder="搜索"
            style="width: 100px"
            suffix-icon="el-icon-search"
            @keyup.enter="innerChange"
            @input="innerChange"
          ></el-input>
        </div>
      </div>
      <div class="tableList">
        <el-table
          v-loading="innerLoading"
          :data="addTableData"
          border
          style="width: 100%"
        >
          <el-table-column prop="name" label="姓名" align="center">
          </el-table-column>
          <el-table-column prop="gradeName" label="年级" align="center">
          </el-table-column>
          <el-table-column prop="classNum" label="班级" align="center">
          </el-table-column>
          <el-table-column prop="studentNo" label="学籍号" align="center">
          </el-table-column>
          <el-table-column prop="date" label="操作" align="center">
            <template slot-scope="{ row }">
              <el-button type="text" @click="add(row)">添加</el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div class="pagination">
        <el-pagination
          :current-page.sync="innerForm.current"
          :page-sizes="[10, 20, 50, 100, 200, 500, 1000]"
          :page-size="innerForm.size"
          layout="sizes, prev, pager, next"
          :total="innerTotal"
          @size-change="handleInnerSizeChange"
          @current-change="handleInnerCurrentChange"
        >
        </el-pagination>
      </div>
    </el-dialog>
  </el-dialog>
</template>
<script>
import {
  classlistbylevelandyear,
  getTrackPage,
  addpage,
  addtrackstudent,
  deletetrackstudent,
} from "@/core/api/newOther/index";
import { getYear } from "@/core/util/util";
export default {
  props: {
    teacherId: {
      type: [String, Number, null],
      default: null,
    },
  },
  data() {
    return {
      trackForm: {
        size: 10,
        current: 1,
      },
      total: 0,
      innerTotal: 0,
      trackVisible: true,

      innerTrackVisible: false,
      yearList: [...getYear()],
      tableData: [],
      addTableData: [],
      levelOptions: [
        {
          value: 2,
          label: "初中",
        },
        {
          value: 3,
          label: "高中",
        },
      ],
      classList: [],
      innerForm: {
        size: 10,
        current: 1,
      },
      loading: false,
      innerLoading: false,
      outClassList: [],
    };
  },
  mounted() {
    this.$nextTick(() => {
      if (this.teacherId !== null) {
        // this.getClasslist();
        this.getTrackPage();
      }
    });
  },
  methods: {
    innerChange() {
      this.innerForm.current = 1;
      this.getAddPage();
    },
    innerLevelChange() {
      if (this.innerForm.level && this.innerForm.year) {
        this.getClasslist();
      } else {
        this.classList = [];
      }
      this.innerChange();
    },
    outLevelChange() {
      if (this.trackForm.level && this.trackForm.year) {
        this.getOutClasslist();
      } else {
        this.outClassList = [];
      }
      this.getTrackPage();
    },

    addFormChange() {
      this.trackForm.current = 1;
      this.getTrackPage();
    },
    handleInnerSizeChange(val) {
      this.innerForm.size = val;
      this.getAddPage();
    },
    handleInnerCurrentChange(val) {
      this.innerForm.current = val;
      this.getAddPage();
    },
    handleSizeChange(val) {
      this.trackForm.size = val;
      this.getTrackPage();
    },
    handleCurrentChange(val) {
      this.trackForm.current = val;
      this.getTrackPage();
    },
    async getClasslist() {
      const res = await classlistbylevelandyear({
        teacherId: this.teacherId,
        level: this.innerForm.level,
        year: this.innerForm.year,
      });
      this.classList = res.data.data;
    },
    async getOutClasslist() {
      const res = await classlistbylevelandyear({
        teacherId: this.teacherId,
        level: this.trackForm.level,
        year: this.trackForm.year,
      });
      this.outClassList = res.data.data;
    },
    handleClose() {
      this.$emit("close");
    },
    async getAddPage() {
      this.innerLoading = true;
      try {
        const res = await addpage({
          ...this.innerForm,
          teacherId: this.teacherId,
        });
        this.addTableData = res.data.data.records;
        this.innerTotal = res.data.data.total;
        this.innerLoading = false;
      } catch {
        this.innerLoading = false;
      }
    },
    async getTrackPage() {
      this.loading = true;
      try {
        const res = await getTrackPage({
          ...this.trackForm,
          teacherId: this.teacherId,
        });
        this.tableData = res.data.data.records;
        this.total = res.data.data.total;
        this.loading = false;
      } catch {
        this.loading = false;
      }
    },
    del(row) {
      this.$confirm("是否从跟踪生中删除该学生, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          deletetrackstudent({
            teacherId: this.teacherId,
            studentId: row.studentId,
          }).then(() => {
            this.getTrackPage();
            this.$message({
              type: "success",
              message: "删除成功!",
            });
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    add(row) {
      addtrackstudent({
        teacherId: this.teacherId,
        studentId: row.studentId,
      }).then(() => {
        this.getAddPage();
        this.getTrackPage();
        this.$message({
          showClose: true,
          message: "添加成功",
          type: "success",
        });
      });
    },
    addtrack() {
      this.innerForm = {
        size: 10,
        current: 1,
      };
      this.innerTrackVisible = true;
      this.getAddPage();
    },
  },
};
</script>
<style lang="scss" scoped>
.filter {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  box-sizing: border-box;
  padding-bottom: 24px;
  .row {
    margin-right: 12px;
  }
}
.pagination {
  box-sizing: border-box;
  padding: 20px 0;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
</style>
